import "./CreateGroupManuallyPage.css"

import api from "constants/endpoints.constants"
import pageIds from "constants/pageIds.constants.json"
import pageTitles from "constants/pageTitles.constants.json"
import GENERAL_TEXTS, { GROUPS_TEXTS, REGISTER_TEXTS } from "constants/text.constants"

import useScrollToTop from "hooks/useScrollToTop"
import useUpdatePageTitle from "hooks/useUpdatePageTitle"

import { useCallback, useContext, useEffect, useRef, useState } from "react"

import { RegistriesContext } from "context/RegistriesContext"

import { fetchRegistries } from "services/fetchers"
import { getJSONrequestWithCsrf, postWithBodyAndCsrf } from "services/apiService"

import { Employees, EmployeesTableData, Header, IGetEmployees } from "types/sharedTypes"
import prepareCsrfToken from "utils/prepareCsrfToken"
import idMapper from "utils/idMapper"

import { ReactComponent as SaveIcon } from "assets/save.icon.svg"
import { ReactComponent as CleanIcon } from "assets/broom-cleaning-icon.svg"
import { ReactComponent as CirclePlusIcon } from "assets/circle-plus-solid.icon.svg"
import { ReactComponent as UsersIcon } from "assets/users.icon.svg"

import { AgGridReact } from "ag-grid-react"
import { useLocation, useNavigate } from "react-router-dom"
import { FirstDataRenderedEvent, RowClickedEvent } from "ag-grid-community"

import Input from "components/atoms/input/Input"
import Error from "components/atoms/error/Error"
import Warning from "components/atoms/warning/Warning"
import Button from "components/atoms/button/Button"
import PageHeader from "components/cores/pageHeader/PageHeader"
import Confirmation from "components/atoms/confirmation/Confirmation"
import SelectRegistry from "components/cores/selectRegistry/SelectRegistry"
import ContentSkeleton from "components/atoms/contentSkeleton/ContentSkeleton"

import SelectableEmployeesTable from "./components/SelectableEmployeesTable"
import SelectedEmployeesTable from "./components/SelectedEmployeesTable"

type SelectedEmployeesObject = {
    Name: string
    RegistryId: string
    Employees: string[]
}

const CreateGroupManuallyPage = () => {
    const location = useLocation()
    const { locationRegistryId, locationRegistryName, locationGroupId, locationGroupName } = location.state || {}
    const { registries, setRegistries, registriesFetched, setRegistriesFetched } = useContext(RegistriesContext)
    const [registryId, setRegistryId] = useState(
        locationRegistryId || (registriesFetched && registries.length ? registries[0].Id : "")
    )
    const [noRegisters, setNoRegisters] = useState(!registries.length && registriesFetched)
    const [fetchingRegistries, setFetchingRegistries] = useState(!registries.length)
    const [fetchError, setFetchError] = useState("")
    const [postError, setPostError] = useState("")
    const [employees, setEmployees] = useState<Employees[]>()
    const [groupEmployees, setGroupEmployees] = useState<Employees[]>([])
    const [selectedEmployees, setSelectedEmployees] = useState<Employees[]>()
    const gridRef = useRef<AgGridReact<EmployeesTableData>>(null)
    const [tableHeaderRows, setTableHeaderRows] = useState<Header>()
    const [showConfirmation, setShowConfirmation] = useState(false)
    const [showGroupUpdatedConfirmation, setShowGroupUpdatedConfirmation] = useState(false)
    const [showWarning, setShowWarning] = useState(false)
    const [fetchingEmployees, setFetchingEmployees] = useState(false)
    const [fetchingGroupEmployees, setFetchingGroupEmployees] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [selectedEmployeesObject, setSelectedEmployeesObject] = useState<SelectedEmployeesObject>({
        Name: locationGroupName || "",
        RegistryId: "",
        Employees: [],
    })

    const navigate = useNavigate()

    const deselectAllRows = () => gridRef.current?.api.forEachNode((node) => node.setSelected(false))
    const selectAllRows = () => {
        gridRef.current?.api.forEachNode((node) => {
          const isInChildrenAfterAggFilter = node.parent?.childrenAfterAggFilter?.includes(node);
      
          if (isInChildrenAfterAggFilter) {
            node.setSelected(true);
          }
        });
      };
      

    const checkDisabled = () => selectedEmployeesObject.Name === "" || selectedEmployeesObject.Employees.length < 1
    const editingManualGroup = !!location.state

    const getEmployees = (regId: string) => {
        setFetchingEmployees(true)
        prepareCsrfToken().then((csrfToken) =>
            postWithBodyAndCsrf(api.getEmployees, csrfToken, JSON.stringify({ id: regId }))
                .then((registerResponse: IGetEmployees) => {
                    setEmployees(registerResponse.Employees)
                    setTableHeaderRows(registerResponse.Header)
                    setFetchingEmployees(false)
                })
                .catch(() => {
                    setFetchError(GENERAL_TEXTS.POST_ERROR_GENERIC)
                    setFetchingEmployees(false)
                })
        )
    }

    const getGroupEmployees = (groupId: string) => {
        setFetchingGroupEmployees(true)
        prepareCsrfToken().then((csrfToken) =>
            getJSONrequestWithCsrf(idMapper(api.getGroupEmployeesById2, groupId), csrfToken)
                .then((response: Employees[]) => {
                    setGroupEmployees(response)
                    setFetchingGroupEmployees(false)
                })
                .catch(() => {
                    setFetchError(GENERAL_TEXTS.POST_ERROR_GENERIC)
                    setFetchingGroupEmployees(false)
                })
        )
    }

    const createManualGroup = () => {
        if (selectedEmployeesObject.Name.length <= 50) {
            setIsLoading(true)
            setPostError("")
            prepareCsrfToken().then((csrfToken) =>
                postWithBodyAndCsrf(
                    api.createManualGroup,
                    csrfToken,
                    JSON.stringify({
                        Name: selectedEmployeesObject.Name,
                        RegistryId: selectedEmployeesObject.RegistryId,
                        Employees: selectedEmployeesObject.Employees,
                    }),
                    true
                )
                    .then(() => {
                        setIsLoading(false)
                        setShowConfirmation(true)
                        setTimeout(() => {
                            setShowConfirmation(false)
                        }, 5000)
                        setSelectedEmployees([])
                        setSelectedEmployeesObject({ Name: "", RegistryId: registryId, Employees: [] })
                        deselectAllRows()
                    })
                    .catch((error) => {
                        setIsLoading(false)
                        error.text().then((response: string) => {
                            if (response.includes("UnvalidName")) {
                                setPostError(GROUPS_TEXTS.MANUAL_GROUP_UNVALID_NAME)
                            } else setPostError(`${GROUPS_TEXTS.ERROR_CREATING_MANUAL_GROUP} ${response}`)
                        })
                    })
            )
        } else {
            setPostError(GROUPS_TEXTS.MANUAL_GROUP_NAME_LIMITED)
        }
    }

    const updateManualGroup = (groupId: string) => {
        setShowGroupUpdatedConfirmation(false)

        if (selectedEmployeesObject.Name.length <= 50) {
            setIsLoading(true)
            setPostError("")
            setShowWarning(false)
            prepareCsrfToken().then((csrfToken) =>
                postWithBodyAndCsrf(
                    api.updateManualGroup,
                    csrfToken,
                    JSON.stringify({
                        Employees: selectedEmployeesObject.Employees,
                        GroupId: groupId,
                        Name: selectedEmployeesObject.Name,
                    }),
                    true
                )
                    .then(() => {
                        setIsLoading(false)
                        setSelectedEmployees([])
                        setSelectedEmployeesObject({ Name: "", RegistryId: registryId, Employees: [] })
                        deselectAllRows()
                        setShowGroupUpdatedConfirmation(true)
                        // navigate("/groups/show")
                    })
                    .catch((error) => {
                        setIsLoading(false)
                        error.text().then((response: string) => {
                            if (response.includes("UnvalidName")) {
                                setPostError(GROUPS_TEXTS.MANUAL_GROUP_UNVALID_NAME)
                            } else setPostError(`${GROUPS_TEXTS.ERROR_CREATING_MANUAL_GROUP} ${response}`)
                        })
                    })
            )
        } else {
            setPostError(GROUPS_TEXTS.MANUAL_GROUP_NAME_LIMITED)
        }
    }

    const onSelectionChanged = (event: RowClickedEvent) => {
        const selectedRows = event.api.getSelectedRows()
        const employeeSelection: Employees[] = []
        const employeeIds: string[] = []
        selectedRows?.forEach((selectedRow) => {
            const selectedEmployee = employees?.find((employee) => employee.Ssn === selectedRow.Pnr)
            if (selectedEmployee) {              
                employeeSelection.push(selectedEmployee)
                employeeIds.push(selectedEmployee.Id)
            }
        })
        setSelectedEmployees(employeeSelection)
        setSelectedEmployeesObject({ ...selectedEmployeesObject, Employees: employeeIds })
    }

    const onFirstDataRendered = useCallback(
        (params: FirstDataRenderedEvent<EmployeesTableData>) => {
            if (location && locationGroupId) {
                if (groupEmployees.length) {
                    const employeeIds = groupEmployees.map((employee) => employee.Ssn)
                    gridRef.current?.api.forEachNode((node) => {
                        node.setSelected(!!node.data && employeeIds.includes(node.data.Pnr))
                    })
                }
            }
        },
        [groupEmployees, location, locationGroupId]
    )

    useEffect(() => {
        if (locationGroupId) getGroupEmployees(locationGroupId)
    }, [locationGroupId])

    useEffect(() => {
        if (!registries.length && !registriesFetched)
            fetchRegistries(
                setRegistries,
                setNoRegisters,
                setRegistryId,
                setFetchingRegistries,
                setRegistriesFetched,
                setFetchError
            )
    }, [registries, setRegistries, registriesFetched, setRegistriesFetched, registryId])

    useEffect(() => {
        if (registryId) {
            getEmployees(registryId)
        }
        if (!editingManualGroup) {
            setSelectedEmployeesObject({
                Name: "",
                RegistryId: registryId,
                Employees: [],
            })
        }
        setSelectedEmployees([])
        deselectAllRows()
    }, [registryId, editingManualGroup])

    useUpdatePageTitle(pageTitles.CREATE_MANUALGROUPS_PAGE_TITLE)
    useScrollToTop()

    return (
        <>
            <PageHeader pageId={pageIds.CREATE_GROUPS_MANUALLY_PAGE_ID} />
            {fetchError && <Error>{fetchError}</Error>}
            {noRegisters && <p>{REGISTER_TEXTS.NO_REGISTERS}</p>}
            {!fetchError && fetchingRegistries && !noRegisters && <ContentSkeleton />}
            {!fetchingRegistries && !editingManualGroup && !showGroupUpdatedConfirmation && (
                <SelectRegistry registries={registries} setRegistryId={setRegistryId} />
            )}
            {!postError && (fetchingEmployees || fetchingGroupEmployees) && <ContentSkeleton />}
            <div className="maxWidth1000">
                {locationGroupName &&
                    locationRegistryName &&
                    !fetchingEmployees &&
                    !fetchingGroupEmployees &&
                    !showGroupUpdatedConfirmation && (
                        <h1>
                            {GROUPS_TEXTS.LABEL_MODIFY_GROUP} {locationGroupName} {GROUPS_TEXTS.LABEL_FROM_REGISTRY}{" "}
                            {locationRegistryName}
                        </h1>
                    )}
                {tableHeaderRows &&
                    employees &&
                    !fetchingEmployees &&
                    !fetchingGroupEmployees &&
                    !showGroupUpdatedConfirmation && (
                        
                        <>
                            <p>{GROUPS_TEXTS.SELECT_EMPLOYEES}</p>
                            <SelectableEmployeesTable
                                gridRef={gridRef}
                                onFirstDataRendered={onFirstDataRendered}
                                header={tableHeaderRows}
                                employees={employees}
                                rowMultiSelectWithClick
                                onSelectionChanged={onSelectionChanged}
                            />
                            <div className="groupSelection">
                                <Button onClick={selectAllRows}>{GROUPS_TEXTS.SELECT_ALL_EMPLOYEES}</Button>
                                <Button
                                    Icon={CleanIcon}
                                    onClick={deselectAllRows}
                                    disabled={!selectedEmployeesObject.Employees.length}
                                >
                                    {GROUPS_TEXTS.CLEAR_GROUP_SELECTION}
                                </Button>
                            </div>
                        </>
                    )}
            </div>
            <div className="maxWidth1000 mb16">
                {postError && <Error>{postError}</Error>}
                {showConfirmation && <Confirmation>{GROUPS_TEXTS.MANUAL_GROUP_CREATED}</Confirmation>}
                {showGroupUpdatedConfirmation && (
                    <div>
                        <div className="mb16">
                            <Confirmation>{GROUPS_TEXTS.MANUAL_GROUP_UPDATED}</Confirmation>
                        </div>
                        <div>
                            <Button
                                onClick={() => navigate("/groups/show", { state: { locationRegistryId: registryId } })}
                                Icon={UsersIcon}
                            >
                                {GROUPS_TEXTS.MANUAL_GROUP_BACK_TO_SHOW_GROUPS}
                            </Button>
                        </div>
                    </div>
                )}
                {showWarning && (
                    <Warning onClick={() => updateManualGroup(locationGroupId)} showWarning={setShowWarning}>
                        {GROUPS_TEXTS.CHANGE_MANUAL_GROUP_WARNING}
                    </Warning>
                )}
            </div>
            {tableHeaderRows && selectedEmployees && selectedEmployees.length > 0 && !showGroupUpdatedConfirmation && (
                <div className="maxWidth1000">
                    <p className="p-no-margin-top">{GROUPS_TEXTS.PREVIEW_MANUAL_GROUP}</p>
                    <SelectedEmployeesTable header={tableHeaderRows} employees={selectedEmployees} gridRef={gridRef} />
                    <div className="groupCreation">
                        <div className="groupNameField">
                            <label className="mb32" htmlFor="groupName">
                                {GROUPS_TEXTS.MANUAL_GROUP_NAME}
                            </label>
                            <Input
                                type="text"
                                id="groupName"
                                name="groupName"
                                value={selectedEmployeesObject.Name}
                                onChange={(e) => {
                                    setSelectedEmployeesObject({ ...selectedEmployeesObject, Name: e.target.value })
                                }}
                            />
                        </div>
                        {!locationGroupId && (
                            <div className="mb32">
                                <Button
                                    Icon={CirclePlusIcon}
                                    onClick={createManualGroup}
                                    disabled={checkDisabled()}
                                    isLoading={isLoading}
                                >
                                    {GROUPS_TEXTS.CREATE_MANUAL_GROUP}
                                </Button>
                            </div>
                        )}
                        {locationGroupId && (
                            <div className="groupChanging">
                                <div className="mb32">
                                    <Button
                                        onClick={() =>
                                            navigate("/groups/show", { state: { locationRegistryId: registryId } })
                                        }
                                    >
                                        {GROUPS_TEXTS.CANCEL_CHANGE_MANUAL_GROUP}
                                    </Button>
                                </div>
                                <div className="mb32">
                                    <Button
                                        Icon={SaveIcon}
                                        onClick={() => setShowWarning(true)}
                                        disabled={checkDisabled()}
                                        isLoading={isLoading}
                                    >
                                        {GROUPS_TEXTS.SAVE_MANUAL_GROUP}
                                    </Button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </>
    )
}

export default CreateGroupManuallyPage
